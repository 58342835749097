// import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button.js';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse.js';
import 'bootstrap/js/dist/dropdown.js';
import 'bootstrap/js/dist/modal.js';
import 'bootstrap/js/dist/popover.js';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab.js';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip.js';